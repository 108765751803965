<script lang="ts" setup></script>

<template>
    <ImageSlider />

    <div class=" items-center gap-2 sm:gap-3 md:flex ">
    <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[48rem] flex-row">
  <div
    class="relative w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-r-none bg-clip-border rounded-xl shrink-0">
    <img
      src="~/assets/images/asesoriacontable.png"
      alt="Asesores Contables" class="object-cover w-full h-full" />
  </div>
  <div class="p-6">
    <h6
      class="block mb-4 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Asesores Contables
    </h6>
    <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-white bg-gradient-to-r from-blue-800 to-indigo-900 bg-opacity-50 rounded-lg transition-all" style="width: 50%; padding: 5px;" >
        <span class="relative z-10">Más de 20 años</span>
    </h4>
    <p class="block mb-8 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
        El Outsourcing es una tendencia mundial que permite a las empresas concentrarse en la esencia de su negocio y encargar las actividades complementarias a empresas especializadas.
Lexcob S.A. no solo garantiza contar con la información contable – financiera oportuna, sino que además orienta y proporciona ...
    </p>
    <a href="#" class="inline-block"><button
        class="flex items-center gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-gray-900 uppercase align-middle transition-all rounded-lg select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none hover:bg-gradient-to-r from-blue-800 to-indigo-900 active:bg-white hover:text-white"
        type="button">
        leer más<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
          stroke-width="2" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
  </div>
</div>  


<div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[48rem] flex-row">
  <div
    class="relative w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-r-none bg-clip-border rounded-xl shrink-0">
    <img
      src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
      alt="Asesores Legales" class="object-cover w-full h-full" />
  </div>
  <div class="p-6">
    <h6
      class="block mb-4 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Asesoría Legal
    </h6>
    <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-white bg-gradient-to-r from-blue-800 to-indigo-900 bg-opacity-50 rounded-lg transition-all" style="width: 50%; padding: 5px;" >
        <span class="relative z-10">Más de 10 años</span>
    </h4>
    <p class="block mb-8 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
        Ser una empresa distinguida reconocida, que se desarrolle en forma segura, confiable, sólida, flexible, rentable, con la calidad humana y ética de nuestra gente, con una gestión que se anticipe y adapte al cambio, iinnovación continua y permanente de acuerdo a la experiencia obtenida ...
    </p>
    <a href="#" class="inline-block "><button
        class="flex items-center gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-gray-900 uppercase align-middle transition-all rounded-lg select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none hover:bg-gradient-to-r from-blue-800 to-indigo-900 active:bg-white hover:text-white"
        type="button">
        leer más<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
          stroke-width="2" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
  </div>
</div>  
</div>  

    
</template>

<style scoped>



h4:hover span.relative {
    color: white;
}


</style>

